body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sliderTrack {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 7.7vw;
}

/* .carousel_thumb {
  float: left;
} */

video {
  width: 100%;
}

#creative_holder {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainSlider {
  width: 70vw;
}

.fullWrapper {
  display: flex;
  align-items: stretch;
}

.fullWrapper.infoGal .mainContainer {
  width: 60%;
  float: left;
}

.fullWrapper.infoGal .infoContainer {
  width: 40%;
  float: left;
}

.fullWrapper.infoGal .infoContainer > div {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.main_img {
  width: 70vw;
  padding: 20% 0;
  background-size: 100% 100%;
}

#log {
  width: 100%;
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;
}

#log ul {
  margin: 0;
  padding: 0;
}

#log li {
  list-style: none;
  padding: 10px;
  margin: 0;
}

#log li:nth-child(even) {
  background: rgba(0, 0, 0, 1);
}

.overlayImg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 56.25%;
}

.mainSlider {
  opacity: 0;
}
